import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const conversationsSlice = createApi({
  reducerPath: 'conversation',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Conversations', 'Get_Conversation', 'Flagged-words', 'Unread'],
  endpoints: (builder) => ({
    getConversationList: builder.query({
      query: () => 'chats/conversations/me',
      providesTags: ['Conversations'],
    }),
    getConversationMessages: builder.query({
      query: (id) => `chats/messages/${id}`,
      providesTags: (id) => [{ type: 'Get_Conversation', id }],
    }),
    clearMessages: builder.mutation({
      query: (payload) => ({
        url: 'learner/clear-chat',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ({ conversationId }) => [
        { type: 'Get_Conversation', id: conversationId },
        'Conversations',
      ],
    }),
    flaggedWords: builder.query({
      query: () => '/flagged-word',
      providesTags: ['Flagged-words'],
    }),
    getUnreadMessages: builder.query({
      query: () => '/chats/messages/unread',
      providesTags: ['Unread'],
    }),
    deleteUnread: builder.mutation({
      query: (chatId) => ({
        url: `/chats/messages/unread/${chatId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Unread' }],
    }),
  }),
});

export const {
  useGetConversationListQuery,
  useGetConversationMessagesQuery,
  useClearMessagesMutation,
  useFlaggedWordsQuery,
  useGetUnreadMessagesQuery,
  useDeleteUnreadMutation,
} = conversationsSlice;
