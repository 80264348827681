import { motion } from 'framer-motion';

import './index.scss';

const Button = ({
  onClick,
  className,
  children,
  background = 'var(--purple-2)',
  color = 'var(--white)',
  width = '100%',
  padding = '20px',
  borderRadius = '20px',
  fontWeight,
  margin,
  type,
  disabled,
}) => {
  return (
    <motion.button
      className={`${className} button`}
      disabled={disabled}
      onClick={onClick}
      style={{
        background,
        color,
        width,
        padding,
        borderRadius,
        fontWeight,
        margin,
      }}
      type={type}
    >
      {children}
    </motion.button>
  );
};

export default Button;
