import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const progressTrackerSlice = createApi({
  reducerPath: 'progressTrackerSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET_PROGRESS', 'TOP_PROGRESS'],
  endpoints: (builder) => ({
    studyTracker: builder.mutation({
      query: (payload) => ({
        url: '/progress-trackers',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['TOP_PROGRESS'],
    }),
    allProgress: builder.query({
      query: () => '/progress-trackers/summary',
      providesTags: ['GET_PROGRESS'],
    }),
    examSuccessPro: builder.mutation({
      query: (payload) => ({
        url: '/progress-trackers/examsuccess',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['TOP_PROGRESS'],
    }),
    topProgress: builder.query({
      query: () => '/progress-trackers/top-summary',
      providesTags: ['TOP_PROGRESS'],
      refetchOnMountOrArgChange: true,
    }),
    schoolTopProgress: builder.query({
      query: (schoolId) =>
        `/progress-trackers/top-summary?schoolId=${schoolId}`,
      providesTags: ['TOP_PROGRESS'],
      refetchOnMountOrArgChange: true,
    }),
    eSAvg: builder.query({
      query: () => '/progress-trackers/examsuccess/avg',
      providesTags: ['TOP_PROGRESS'],
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useStudyTrackerMutation,
  useAllProgressQuery,
  useExamSuccessProMutation,
  useTopProgressQuery,
  useSchoolTopProgressQuery,
  useESAvgQuery,
} = progressTrackerSlice;
