import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  subject: '',
  subjectId: '',
  guides: [],
  editId: 0,
  guideId: '',
  screen: 'create',
  editMode: false,
};

export const studyGuideSlice = createSlice({
  name: 'studyGuideSlice',
  initialState,
  reducers: {
    setTitle: (state, item) => {
      state.title = item.payload;
    },
    setSubject: (state, item) => {
      state.subject = item.payload;
    },
    guideObj: (state, item) => {
      const id = item.payload.id;
      const findDub = state.guides.filter((current) => current.id !== id);
      state.guides = [...findDub, item.payload];
      state.editId = 0;
    },
    removeObj: (state, item) => {
      const find = state.guides.filter(
        (guide) => guide.topicId !== item.payload
      );
      state.guides = find;
      state.editId = 0;
    },
    clearObj: (state) => {
      state.guides = [];
    },
    editObj: (state, item) => {
      state.editId = item.payload;
    },
    updateAll: (state, item) => {
      state.title = item.payload.title;
      state.subject = item.payload.subject.title;
      state.subjectId = item.payload.subject;
      state.editId = 0;
      state.guideId = item.payload._id;
      state.guides = item.payload.topics.map((data) => ({
        topics: data.title,
        id: data?._id,
        day: data.day,
        time: data.time,
        topicId: data.topicId,
      }));
      state.editMode = true;
    },
    resetObj: (state) => {
      state.title = '';
      state.subject = '';
      state.subjectId = '';
      state.guides = [];
      state.editId = 0;
      state.guideId = '';
      state.editMode = false;
    },
    setScreen: (state, item) => {
      state.screen = item.payload;
    },
  },
});

export const {
  setTitle,
  setNote,
  guideObj,
  removeObj,
  setSubject,
  clearObj,
  editObj,
  updateAll,
  resetObj,
  setScreen,
} = studyGuideSlice.actions;

export default studyGuideSlice.reducer;
