import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tutorTaskSlice = createApi({
  reducerPath: 'tutorTaskSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Tutor_tasks'],
  endpoints: (builder) => ({
    getTutorTasks: builder.query({
      query: () => `/tutor/get-tasks`,
      providesTags: ['Tutor_tasks'],
    }),
    getSingleTutorTasks: builder.query({
      query: (taskId) => `/tutor/get-tasks/${taskId}`,
    }),
    deleteTutorTask: builder.mutation({
      query: (taskId) => ({
        url: `/tutor/delete-task/${taskId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tutor_tasks'],
    }),
    createTutorTask: builder.mutation({
      query: (payload) => ({
        url: `/tutor/create-task`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Tutor_tasks'],
    }),
    editTutorTask: builder.mutation({
      query: (taskId) => ({
        url: `/tutor/delete-task/${taskId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Tutor_tasks'],
    }),
    searchTutorTasks: builder.query({
      query: (param) => `/tutor/get-tasks?search=${param}`,
    }),
  }),
});

export const {
  useGetTutorTasksQuery,
  useGetSingleTutorTasksQuery,
  useDeleteTutorTaskMutation,
  useEditTutorTaskMutation,
  useSearchTutorTasksQuery,
} = tutorTaskSlice;
