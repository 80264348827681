import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const mockExamSlice = createApi({
  reducerPath: 'mockExamSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET', 'Exam'],
  endpoints: (builder) => ({
    getMockExams: builder.query({
      query: () => `resource/mock-exams`,
    }),
    getSchoolMockExams: builder.query({
      query: (schoolId) => `resource/mock-exams?schoolId=${schoolId}`,
    }),
    getMockExamSubject: builder.query({
      query: (id) => `resource/mock-exams/${id}/subjects`,
    }),
    getMockExamSubjectCode: builder.query({
      query: ({ mockExamId, subjectId }) =>
        `resource/mock-exams/${mockExamId}/subjects/${subjectId}`,
    }),
    getMockExamQuestionTypes: builder.query({
      query: ({ mockExamId }) => ({
        url: `resource/mock-exams/mock-exam-assessments/${mockExamId}`,
      }),
    }),
    submitQuestionTypes: builder.mutation({
      query: (payload) => ({
        url: `resource/mock-exams/mock-exam-assessments`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetMockExamsQuery,
  useGetSchoolMockExamsQuery,
  useLazyGetMockExamsQuery,
  useGetMockExamSubjectQuery,
  useLazyGetMockExamSubjectQuery,
  useGetMockExamSubjectCodeQuery,
  useLazyGetMockExamSubjectCodeQuery,
  useGetMockExamQuestionTypesQuery,
  useLazyGetMockExamQuestionTypesQuery,
  useSubmitQuestionTypesMutation,
} = mockExamSlice;
