import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const examSuccessSlice = createApi({
  reducerPath: 'examSuccessSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET_EXAMS'],
  endpoints: (builder) => ({
    getExams: builder.query({
      query: () => 'resource/exam-success',
      providesTags: ['GET_EXAMS'],
    }),
    getSchoolExams: builder.query({
      query: (schoolId) => `resource/exam-success?schoolId=${schoolId}`,
      providesTags: ['GET_EXAMS'],
    }),
    getSingleExam: builder.query({
      query: (id) => `examsuccess/${id}`,
    }),
    singleExamClass: builder.query({
      query: (id) => `examsuccess-classifications/learner/${id}`,
    }),
    allExamClass: builder.query({
      query: (id) => `examsuccess-classifications/get-class/${id}`,
    }),
    postExamSuccessProgress: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/examsuccess',
        method: 'POST',
        body: payload,
      }),
    }),
    getESP: builder.query({
      query: () => 'progress-trackers/examsuccess',
    }),
    getESDay: builder.query({
      query: (id) => `examsuccess-days/learner/${id}`,
    }),
  }),
});

export const {
  useGetExamsQuery,
  useGetSchoolExamsQuery,
  useGetSingleExamQuery,
  useSingleExamClassQuery,
  useAllExamClassQuery,
  usePostExamSuccessProgressMutation,
  useGetESPQuery,
  useGetESDayQuery,
} = examSuccessSlice;
