import ReactDOM from 'react-dom';
import './index.scss';
import backgroundImage from '../../assets/loader-no-background.gif';

const LoaderCenter = ({ hideBackGround = false }) => {
  const loaderRoot =
    document.getElementById('loader-root') || createLoaderRoot();

  const preventClickPropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return ReactDOM.createPortal(
    <div
      className={`loader-center`}
      style={{
        background: hideBackGround ? 'transparent' : 'rgba(0, 0, 0, 0.05)',
      }}
      onClick={preventClickPropagation}
    >
      <img
        src={backgroundImage}
        width="200"
        height="auto"
        alt="loading"
        className="loader-image"
      />
    </div>,
    loaderRoot
  );
};

const createLoaderRoot = () => {
  const loaderRoot = document.createElement('div');
  loaderRoot.id = 'loader-root';
  document.body.appendChild(loaderRoot);
  return loaderRoot;
};

export default LoaderCenter;
