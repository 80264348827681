import { lazy, Suspense } from 'react';
import LoaderCenter from '../components/LoaderCenter';
import { createBrowserRouter } from 'react-router-dom';
const TutorData = lazy(() => import('../service/TutorData'));
const ProtectedRoute = lazy(() => import('../service/ProtectedRoute'));
const UserData = lazy(() => import('../service/UserData'));
const Login = lazy(() => import('../pages/Login'));
const Signup = lazy(() => import('../pages/Signup'));
const GeneralSignup = lazy(() => import('../pages/GeneralSignup'));
const Plan = lazy(() => import('../pages/Plan'));
const Customise = lazy(() => import('../pages/Customise'));
const Home = lazy(() => import('../pages/Home'));
const TutorHome = lazy(() => import('../pages/TutorHome'));
const TutorTasks = lazy(() => import('../pages/TutorTasks'));
const TutorCommunity = lazy(() => import('../pages/TutorCommunity'));
const TutorResources = lazy(() => import('../pages/TutorResources'));
const TutorStudents = lazy(() => import('../pages/MyStudents'));

// ------
const PaymentRedirect = lazy(() => import('../pages/PaymentRedirect'));
const Resource = lazy(() => import('../pages/Resource'));
const Account = lazy(() => import('../pages/Account'));
const Error404 = lazy(() => import('../pages/Error404'));
const Pay = lazy(() => import('../pages/Pay'));
const StudyGuide = lazy(() => import('../pages/StudyGuide'));
const Privacy = lazy(() => import('../pages/Privacy'));
const Terms = lazy(() => import('../pages/Terms'));
const Help = lazy(() => import('../pages/Help'));
const Study = lazy(() => import('../pages/Study'));
const StudySubject = lazy(() => import('../pages/StudySubject'));
const LessonNote = lazy(() => import('../pages/LessonNote'));
const LessonContent = lazy(() => import('../pages/LessonContent'));
const VideoList = lazy(() => import('../pages/VideoList'));
const VideoLesson = lazy(() => import('../pages/VideoLesson'));
const ResourceVideo = lazy(() => import('../pages/ResourceVideo'));
const Podcast = lazy(() => import('../pages/Podcast'));
const PodcastList = lazy(() => import('../pages/PodcastList'));
const Games = lazy(() => import('../pages/Games'));
const GamePlay = lazy(() => import('../pages/GamePlay'));
const SubjectList = lazy(() => import('../pages/SubjectList'));
const AccountScreen = lazy(() => import('../pages/AccountScreen'));
const Password = lazy(() => import('../pages/Password'));
const QuickScreen = lazy(() => import('../pages/QuickScreen'));
const TutorQuickScreen = lazy(() => import('../pages/TutorSetup'));
const TutorTypeScreen = lazy(() => import('../pages/TutorType'));
const TutorTypeSchoolScreen = lazy(() => import('../pages/AddSchoolOrCenter'));
const TutorRefereeScreen = lazy(() => import('../pages/TutorReferee'));
const TutorVerificationScreen = lazy(() =>
  import('../pages/TutorVerification')
);
const UnprotectedRoute = lazy(() => import('../service/UnprotectedRoute'));
const SavedItems = lazy(() => import('../pages/SavedItems'));
const MockExam = lazy(() => import('../pages/MockExam'));
const Tasks = lazy(() => import('../pages/Tasks'));
const TaskList = lazy(() => import('../pages/TaskItem'));
const PaymentLocal = lazy(() => import('../pages/PaymentLocal'));
const AmountScreen = lazy(() => import('../pages/Amount'));
const PaymentMethod = lazy(() => import('../pages/PaymentMethod'));
const PaymentWithCardScreen = lazy(() => import('../pages/PaymentLocalCard'));
const PaymentStripe = lazy(() => import('../pages/Stripe'));
const PaymentRedirectStripe = lazy(() =>
  import('../pages/PaymentRedirectStripe')
);
const TaskResult = lazy(() => import('../pages/TaskResult'));
const MockExamQuestions = lazy(() => import('../pages/MockExamQuestions'));
const MockExamResult = lazy(() => import('../pages/MockExamResult'));
const StudyTask = lazy(() => import('../pages/StudyTask'));
const Community = lazy(() => import('../pages/Community'));
const ProgressTracker = lazy(() => import('../pages/Progress'));
const Badges = lazy(() => import('../pages/Badges'));
const ProgressDetails = lazy(() => import('../pages/ProgressDetails'));
const ExamSuccess = lazy(() => import('../pages/ExamSuccess'));
const ExamSuccessProgress = lazy(() => import('../pages/ExamSuccessProgress'));
const ExamSuccessStudyStart = lazy(() =>
  import('../pages/ExamSuccessStudyStart')
);
const ExamSuccessStudy = lazy(() => import('../pages/ExamSuccessStudy'));
const ExamSuccessMockExam = lazy(() => '../pages/ExamSuccessMockExam');
const LifeSkills = lazy(() => import('../pages/LifeSkills'));
const LifeSkillsPlayer = lazy(() => import('../pages/LifeSkillsPlayer'));
const LifeSkillsDetails = lazy(() => import('../pages/LifeSkillsDetails'));
const Digipreneur = lazy(() => import('../pages/Digipreneur'));
const ResetPasswordFlow = lazy(() => import('../pages/ResetPasswordFlow'));
const DigiCardDetails = lazy(() => import('../pages/DigiCardDetails'));
const DigiDays = lazy(() => import('../pages/DigiDays'));

const StudyGuideStudy = lazy(() => import('../pages/StudyGuideStudy'));
const MobileNotification = lazy(() => import('../pages/MobileNotification'));
const Chat = lazy(() => import('../pages/Chat'));
const VideoSubjectList = lazy(() => import('../pages/VideoSubjectList'));
const VideoSelected = lazy(() => import('../pages/VideoSelected'));
const PodcastSubject = lazy(() => import('../pages/PodcastSubject'));
const LeaderBoard = lazy(() => import('../pages/LeaderBoard'));
const External3dPage = lazy(() => import('../pages/External3dPage'));

const CompetitionPage = lazy(() => import('../pages/CompetitionList'));
const CompetitionResult = lazy(() => import('../pages/CompetitionResult'));

const TeaChatPage = lazy(() => import('../pages/Teachat'));
const LiveClassPage = lazy(() => import('../pages/LiveClass'));
const TutorLiveClassPage = lazy(() => import('../pages/StartTutorClass'));
const CreateTutorTaskPage = lazy(() => import('../pages/CreateTasks'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/sign-up',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <Signup />
      </Suspense>
    ),
  },
  {
    path: '/sign-up/user',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <GeneralSignup />
      </Suspense>
    ),
  },
  {
    path: '/sign-up/account',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <AccountScreen />,
      </Suspense>
    ),
  },
  {
    path: '/sign-up/password',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <Password />
      </Suspense>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ResetPasswordFlow />
      </Suspense>
    ),
  },
  {
    path: '/sign-up/quick-setup',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <QuickScreen />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/sign-up/tutor-setup',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorQuickScreen />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/sign-up/tutor-type',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorTypeScreen />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/sign-up/tutor-type-school',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorTypeSchoolScreen />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/sign-up/tutor-verify',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorVerificationScreen />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/sign-up/tutor-referee',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorRefereeScreen />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/customise',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <Customise />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/plan',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <Plan />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/payment',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <PaymentLocal />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/home',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Home data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tutor-home',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorData render={(data) => <TutorHome data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tutor-resources',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorData render={(data) => <TutorResources data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tutor-community',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorData render={(data) => <TutorCommunity data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tutor-tasks',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorData render={(data) => <TutorTasks data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tutor-students',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorData render={(data) => <TutorStudents data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Resource data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/study',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Study data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/study/subject/:subjectId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <StudySubject data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/study/topic/:topicId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <LessonNote data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/study/topic-content/:topicId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <LessonContent data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/study/:topicId/video/:videoId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <ResourceVideo data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/videos',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <VideoList data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/video/:videoId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <VideoLesson data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/video-list/:videoId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <VideoSelected data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/video-subject/:subjectId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <VideoSubjectList data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/podcasts',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Podcast data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/podcast/:type',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <PodcastList data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/podcast-subject/:subjectId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <PodcastSubject data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/games',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Games data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/game/:gameId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <GamePlay data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/account',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Account data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/payment/redirect',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <PaymentRedirect data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/pay',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Pay data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/study-guide',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <StudyGuide data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/privacy',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Privacy data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/terms-and-conditions',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Terms data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/help-and-support',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Help data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/subject-list',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <SubjectList data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/saved-items',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <SavedItems data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/mock-exams',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <MockExam data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tasks',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Tasks data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tasks/:taskId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <TaskList data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/payment/wallet/amount',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <AmountScreen data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/payment/method',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <PaymentMethod data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/payment/local/card',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <PaymentWithCardScreen data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/payment/stripe',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <PaymentStripe data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/payment/redirect/stripe',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <PaymentRedirectStripe data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/task/result/:taskId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <TaskResult data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/mock-exam/questions/:id',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <MockExamQuestions data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/mock-exam/result/:id',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <MockExamResult data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/study/task/:id/topicId/:topicId/subjectId/:subjectId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <StudyTask data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/community',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Community data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/school',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Community data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/events',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Community data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/progress-tracker',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <ProgressTracker data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/badges',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Badges data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/progress-details',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <ProgressDetails data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/exam-success',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <ExamSuccess data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/exam-success/:classificationId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <ExamSuccessProgress data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/exam-success/study/:dayId/:topicId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <ExamSuccessStudyStart data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/exam-success/study-content/:dayId/:topicId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <ExamSuccessStudy data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/exam-success/mock-exam/questions/:id',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <ExamSuccessMockExam data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/life-skills',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <LifeSkills data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/life-skills/video-player/:videoId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <LifeSkillsPlayer data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/life-skills/channel/:lifeSkillId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <LifeSkillsDetails data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/life-skills/channel/:lifeSkillId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <LifeSkillsDetails data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/digipreneur',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Digipreneur data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/digipreneur/:id',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <DigiCardDetails data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/digipreneur/days/:groupId/:levelId/:dayId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <DigiDays data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/teachat',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <TeaChatPage data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/teachat/liveclass/:classId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <LiveClassPage data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tutor/liveclass/:classId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorData render={(data) => <TutorLiveClassPage data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tutor-tasks/create-tasks',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <TutorData render={(data) => <CreateTutorTaskPage data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/resources/study-guide/:studyguide/topicId/:topicId/:dayId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <StudyGuideStudy data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/mob-notifications',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <MobileNotification data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/chats',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Chat data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/leader-board',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <LeaderBoard data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/tasks/competition/:compId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <CompetitionPage data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/task/competition/result/:compId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <CompetitionResult data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: '/topic-content/:topicId/content/:contentId',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <External3dPage />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<LoaderCenter />}>
        <ProtectedRoute>
          <UserData render={(data) => <Error404 data={data} />} />
        </ProtectedRoute>
      </Suspense>
    ),
  },
]);
