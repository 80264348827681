import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const walletSlice = createApi({
  reducerPath: 'walletSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    tagTypes: ['Get', 'Wallet'],
    walletBalance: builder.query({
      query: () => `wallet`,
      providesTags: ['Wallet'],
    }),
    fundBalance: builder.mutation({
      query: (payload) => ({
        url: 'wallet/fund',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Wallet' }, { type: 'Transactions' }],
    }),
    debitWallet: builder.mutation({
      query: (payload) => ({
        url: 'wallet/debit',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Wallet' }, { type: 'Transactions' }],
    }),
    getTransactions: builder.query({
      query: () => `wallet/transactions`,
      providesTags: ['Transactions'],
    }),
    confirmPayment: builder.mutation({
      query: (payload) => ({
        url: `/payments/flutter`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Wallet' }, { type: 'Transactions' }],
    }),
    verifyPayment: builder.mutation({
      query: (payload) => ({
        url: `payments/flutter`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Wallet' }, { type: 'Transactions' }],
    }),
    getTransactionsById: builder.query({
      query: ({ transactionId }) => `wallet/transaction/${transactionId}`,
    }),
    payWithWallet: builder.mutation({
      query: (payload) => ({
        url: 'payments/debit-wallet',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useWalletBalanceQuery,
  useFundBalanceMutation,
  useDebitWalletMutation,
  useGetTransactionsQuery,
  useConfirmPaymentMutation,
  useVerifyPaymentMutation,
  useLazyGetTransactionsByIdQuery,
  usePayWithWalletMutation,
} = walletSlice;
