import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const resourceSlice = createApi({
  reducerPath: 'resourceSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET', 'GET_LIKES', 'GET_PODCAST'],
  endpoints: (builder) => ({
    tagTypes: ['Get', 'Subject'],
    subjectData: builder.query({
      query: () => `/resource/study/subject`,
      providesTags: ['Subject'],
    }),
    schoolSubjectData: builder.query({
      query: (schoolId) => `/resource/study/subject?schoolId=${schoolId}`,
      providesTags: ['Subject'],
    }),
    singleSubject: builder.query({
      query: (id) => `/resource/study/subject/${id}`,
      providesTags: ['Subject'],
    }),
    topicData: builder.query({
      query: (id) => `/resource/study/topic/${id}`,
    }),
    videoData: builder.query({
      query: () => '/resource/video',
    }),
    schoolVideoData: builder.query({
      query: (schoolId) => `/resource/video?schoolId=${schoolId}`,
    }),
    singleVideo: builder.query({
      query: (id) => `/resource/video/${id}`,
      providesTags: ['Get'],
    }),
    podcastData: builder.query({
      query: () => '/resource/podcast',
      providesTags: ['GET_PODCAST'],
    }),
    schoolPodcastData: builder.query({
      query: (schoolId) => `/resource/podcast?schoolId=${schoolId}`,
      providesTags: ['GET_PODCAST'],
    }),
    singlePodcastData: builder.query({
      query: ({ id }) => `/resource/podcast/${id}`,
    }),
    gameData: builder.query({
      query: () => '/resource/games',
      providesTags: ['GET_GAME'],
    }),
    schoolGameData: builder.query({
      query: (schoolId) => ` /resource/games?schoolId=${schoolId}`,
      providesTags: ['GET_GAME'],
    }),
    gameSingle: builder.query({
      query: (id) => `/resource/games/${id}`,
      providesTags: ['GET_SINGLE_GAME'],
    }),
    likeItem: builder.mutation({
      query: (payload) => ({
        url: '/likes',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['GET_LIKES'],
    }),
    getSingleLikes: builder.query({
      query: (id) => `likes/${id}`,
      providesTags: ['GET_LIKES'],
    }),
    getStudyTask: builder.query({
      query: (id) => `resource/study/task/${id}`,
    }),
    getStudyStore: builder.query({
      query: (id) => `resource/study/store/${id}`,
    }),
    updateEdu: builder.mutation({
      query: (payload) => ({
        url: 'learner/update/educations-info',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Subject' }],
    }),
    getStudyVideo: builder.query({
      query: ({ topicId, videoId }) => `resource/study/${topicId}/${videoId}`,
    }),
    getVideoSubject: builder.query({
      query: (subjectId) => `resource/video/subjects/${subjectId}`,
    }),
    getPodcastSubject: builder.query({
      query: (subjectId) => `resource/podcast/subjects/${subjectId}`,
    }),
    updateGamePlayed: builder.mutation({
      query: (payload) => ({
        url: 'resource/game-played',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'GET_GAME' }, { type: 'GET_SINGLE_GAME' }],
    }),
  }),
});

export const {
  useTopicDataQuery,
  useSubjectDataQuery,
  useSchoolSubjectDataQuery,
  useLazySubjectDataQuery,
  useVideoDataQuery,
  useSchoolVideoDataQuery,
  useLazyVideoDataQuery,
  usePodcastDataQuery,
  useSchoolPodcastDataQuery,
  useLazyPodcastDataQuery,
  useGameDataQuery,
  useSchoolGameDataQuery,
  useLazyGameDataQuery,
  useLikeItemMutation,
  useGetStudyTaskQuery,
  useGetStudyStoreQuery,
  useUpdateEduMutation,
  useSingleSubjectQuery,
  useGetStudyVideoQuery,
  useSingleVideoQuery,
  useGetLikesQuery,
  useGetSingleLikesQuery,
  useGameSingleQuery,
  useGetVideoSubjectQuery,
  useLazyGetVideoSubjectQuery,
  useGetPodcastSubjectQuery,
  useSinglePodcastDataQuery,
  useLazySinglePodcastDataQuery,
  useUpdateGamePlayedMutation,
} = resourceSlice;
