import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const taskSlice = createApi({
  reducerPath: 'taskSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get', 'MockExamTask'],
  endpoints: (builder) => ({
    tagTypes: ['Get'],
    getStudyTask: builder.query({
      query: (id) => `resource/study/task/${id}`,
    }),
    getTasks: builder.query({
      query: () => `progress-trackers/tasks`,
    }),
    getSchoolTasks: builder.query({
      query: (id) => `progress-trackers/tasks?schoolId=${id}`,
    }),
    getSingleTaskGrade: builder.query({
      query: (id) => `progress-trackers/tasks/${id}`,
    }),
    getSingleTask: builder.query({
      query: ({ id, schoolId }) =>
        `resource/study/task/${id}?schoolId=${schoolId}`,
    }),
    getMockExams: builder.query({
      query: (id) => `progress-trackers/mockexams/${id}`,
    }),
    submitTask: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/',
        method: 'POST',
        body: payload,
      }),
    }),
    mockExamProgress: builder.query({
      query: () => 'progress-trackers/mockexams',
      providesTags: ['MockExamTask'],
    }),
    submitMockExamTask: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/mockexams',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'MockExamTask' }],
    }),
    studyTask: builder.query({
      query: ({ id }) => `/resource/study/task/${id}`,
    }),
    studyTaskSubmit: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/topic-questions',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetStudyTaskQuery,
  useGetTasksQuery,
  useGetSchoolTasksQuery,
  useLazyGetTasksQuery,
  useGetSingleTaskQuery,
  useGetSingleTaskGradeQuery,
  useSubmitTaskMutation,
  useGetMockExamsQuery,
  useSubmitMockExamTaskMutation,
  useMockExamProgressQuery,
  useStudyTaskQuery,
  useStudyTaskSubmitMutation,
} = taskSlice;
