import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const findFriendSlice = createApi({
  reducerPath: 'findFriendSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    tagTypes: ['Get_Friend'],
    getFriendList: builder.query({
      query: () => 'friends',
      providesTags: ['Get_Friend'],
    }),
    sendFriendRequest: builder.mutation({
      query: ({ id }) => ({
        url: `friends/${id}`,
        method: 'POST',
        // body: payload,
      }),
      invalidatesTags: [{ type: 'Get_Friend' }, { type: 'Get_Notification' }],
    }),
    acceptFriendRequest: builder.mutation({
      query: ({ id }) => ({
        url: `friends/${id}/accept`,
        method: 'Put',
        // body: payload,
      }),
      invalidatesTags: [{ type: 'Get_Friend' }],
    }),
    removeFriendRequest: builder.mutation({
      query: ({ id }) => ({
        url: `friends/${id}`,
        method: 'POST',
        // body: payload,
      }),
      invalidatesTags: [{ type: 'Get_Friend' }],
    }),
    rejectFriendRequest: builder.mutation({
      query: ({ id }) => ({
        url: `friends/${id}/reject`,
        method: 'Put',
      }),
      invalidatesTags: [{ type: 'Get_Friend' }],
    }),
    deleteFriendRequest: builder.mutation({
      query: ({ id }) => ({
        url: `friends/${id}/delete`,
        method: 'Delete',
      }),
      invalidatesTags: [{ type: 'Get_Friend' }],
    }),
    unFriendRequest: builder.mutation({
      query: ({ id }) => ({
        url: `friends/${id}/delete`,
        method: 'Delete',
      }),
      invalidatesTags: [{ type: 'Get_Friend' }, { type: 'Tutor' }],
    }),
    shareResource: builder.mutation({
      query: (payload) => ({
        url: 'resource/share',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetFriendListQuery,
  useSendFriendRequestMutation,
  useRemoveFriendRequestMutation,
  useAcceptFriendRequestMutation,
  useShareResourceMutation,
  useRejectFriendRequestMutation,
  useDeleteFriendRequestMutation,
  useUnFriendRequestMutation,
} = findFriendSlice;
