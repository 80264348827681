import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_BASE }),
  endpoints: (builder) => ({
    getRequests: builder.query({
      query: (roomId) => `/tutor/live/${roomId}`,
    }),
  }),
});

export const { useGetRequestsQuery } = apiSlice;
